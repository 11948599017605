import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Navbar from './components/navbar';
import { createContext, lazy, Suspense, useContext, useEffect, useState } from 'react';
import Login from './pages/login';
import Register from './pages/register';
import Navbar2 from './components/navbar2';
const Team = lazy(() => import('./pages/team'));
const Home = lazy(() => import('./pages/home'));
const Profile = lazy(() => import('./pages/profile'));
const Order = lazy(() => import('./pages/order'));
const Recharge = lazy(() => import('./pages/recharge'));
const Transactions = lazy(() => import('./pages/transaction'));
const Account = lazy(() => import('./pages/account'));
const Cashout = lazy(() => import('./pages/cashout'));
const Group = lazy(() => import('./pages/group'));

function App() {




  const [navStatus, editNavStatus] = useState(true)
  function handleNav(status) {
    editNavStatus(status)
  }

  return (  
    <BrowserRouter>
    <Suspense fallback={<div style={{marginTop: '200px'}} className='grid-center'><span className='spinner'></span></div>}>
    <Routes>
        <Route path='/home' element={<Home handleNav={handleNav}/>}/>
        <Route path='/profile' element={<Profile handleNav={handleNav} />}/>
        <Route path='/team' element={<Team handleNav={handleNav} />}/>
        <Route path='/order' element={<Order handleNav={handleNav} />}/>
        <Route path='/recharge' element={<Recharge handleNav={handleNav} />}/>
        <Route path='/transaction' element={<Transactions handleNav={handleNav}  />}/>
        <Route path='/account' element={<Account handleNav={handleNav}  />}/>
        <Route path='/cashout' element={<Cashout handleNav={handleNav} />}/>
        <Route path='/group' element={<Group handleNav={handleNav} />}/>
        <Route path='/' element={<Login handleNav={handleNav} />}/>
        <Route path='/register' element={<Register handleNav={handleNav}/>}/>
      </Routes>
      <Navbar display={navStatus}/>
    </Suspense>
      
      
    </BrowserRouter>
  );
}

export default App;
